@use "buttons"; // will use _buttons.scss
@use "links"; // will use _buttons.scss
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
    font-family: "RussoOne";
    src: url(../fonts/RussoOne.ttf) format("truetype");
}
@font-face {
    font-family: "Whisper";
    src: url(../fonts/Whisper.ttf) format("truetype");
}
@font-face {
    font-family: "PermanentMarker";
    src: url(../fonts/PermanentMarker.ttf) format("truetype");
}
@font-face {
    font-family: "GoodTimes";
    src: url(../fonts/GoodTimes.ttf) format("truetype");
}

$body-color: purple;
$unavailable-color: #420c09;
$available-color: #fff;
$selected-color: #e2e61e;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    --scrollbarBG: rgba(255, 255, 255, 0.1);
    --thumbBG: #ffdab9;
}

*::-webkit-scrollbar {
    width: 11px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 15px;
    border: 3px solid var(--scrollbarBG);
}
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

body {
    .bg {
        overflow: hidden;
        position: absolute;
        filter: contrast(0.2);
        opacity: 95%;
        background: url("../images/129A3186-3.jpg");
        background-size: cover;
        background-position-x: 80%;
        height: 100vh;
        width: 100vw;
        z-index: -1;
    }
    .header {
        position: relative;
        height: auto;
        width: 100vw;
        background: rgba(255, 218, 185, 0.78);
        background: linear-gradient(
            0deg,
            rgba(255, 218, 185, 0) 0%,
            rgba(255, 218, 185, 0.78) 15%,
            rgba(255, 218, 185, 0.78) 100%
        );
        overflow: hidden;

        h1 {
            font-family: "PermanentMarker";
            letter-spacing: 0.2em;
            color: #fff;
            font-size: 6em;
            text-align: center;
            margin: auto 0;
        }
    }
    .container01 {
        height: 100vh;
        width: 100%;
        opacity: 0.8;
        filter: brightness(0.5);
        position: absolute;
        z-index: -1;
    }
    svg {
        padding: 0 15px;
    }
    .hero {
        text-align: center;
    }
    .hero_logo {
        margin-top: 25px;
        filter: drop-shadow(1px 4px 30px black);
        height: 100%;
        max-height: 75%;
        width: 300px;
        object-fit: contain;
    }
    .sliderContainer {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        margin: 0 15px;
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);

        .slider {
            padding: 10px;
            display: grid;
            grid-template-columns: repeat(14, calc(33.33vw - 18px));
            grid-template-rows: auto;
            gap: 10px;
            min-height: 90vh;

            .colCard {
                border-radius: 15px;
                // padding: 0 15px;
                display: flex;
                flex-direction: column;
                scroll-snap-align: start;

                .card_rounded {
                    border-radius: 15px;
                }

                .dataCard_header {
                    padding: 10px 10px;
                    border-bottom: 3px solid black;
                    margin-bottom: 25px;
                    background: rgba(255, 255, 255, 0.85);
                    p {
                        font-family: "Montserrat", sans-serif;
                        color: #000;
                        font-size: 3.5em;
                        &.head {
                            font-family: "GoodTimes";
                        }
                        &.middle {
                            font-weight: 600;
                            font-family: "PermanentMarker";
                            font-size: 8em;
                            margin: -30px 0;
                        }
                    }
                }
                .scheme1 {
                    background-color: rgba(0, 0, 0, 0.493);
                    opacity: 90%;
                    span {
                        color: #fff;
                    }
                }
                .scheme2 {
                    background-color: rgba(255, 255, 255, 0.75);
                }
                .blockContainer {
                    .scheduleBlock {
                        display: flex;
                        flex-direction: column;
                        padding: 15px 5px;
                        border-radius: 10px;
                        margin-bottom: 10px;
                        span {
                            text-align: center;
                        }
                        .span1 {
                            font-family: "PermanentMarker", sans-serif;
                            font-size: 5em;
                        }
                        .span3 {
                            font-family: "GoodTimes", sans-serif;
                            font-size: 2em;

                            &.scheduleTime {
                                font-weight: 600;
                                font-size: 2.5em;
                            }
                        }
                    }
                }
            }
        }
    }

    .space_top {
        margin-top: 35px;
    }
    .text_centered {
        text-align: center;
    }
}
