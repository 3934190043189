@font-face {
  font-family: "RussoOne";
  src: url(../fonts/RussoOne.ttf) format("truetype");
  font-family: "Whisper";
  src: url(../fonts/Whisper.ttf) format("truetype");
}

.link_schedule {
  --border: 5px; /* the border width */
  --slant: 0.7em; /* control the slanted corners */
  --color: #000; /* the color */

  font-size: 35px;
  padding: 0.4em 1.2em;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--color);
  background:
    linear-gradient(to bottom left, var(--color) 50%, #0000 50.1%) top right,
    linear-gradient(to top right, var(--color) 50%, #0000 50.1%) bottom left;
  background-size: calc(var(--slant) + 1.3 * var(--border)) calc(var(--slant) + 1.3 * var(--border));
  background-repeat: no-repeat;
  box-shadow:
    0 0 0 200px inset var(--s, #0000),
    0 0 0 var(--border) inset var(--color);
  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
  transition:
    color var(--t, 0.3s),
    background-size 0.3s;

  &.link_schedule {
    font-size: 2.3rem;
    max-width: 300px;
    font-family: "Montserrat";
    text-align: center;
    align-self: start;
  }
}

.link_schedule:focus-visible {
  outline-offset: calc(-1 * var(--border));
  outline: var(--border) solid #000c;
  clip-path: none;
  background-size: 0 0;
}
.link_schedule:hover,
.link_schedule:active {
  background-size: 100% 100%;
  color: #000;
  --t: 0.2s 0.1s;
  text-decoration: none;
}
.link_schedule:active {
  --s: #0005;
  transition: none;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
